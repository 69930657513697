import React, { useEffect, useState } from 'react';
import ModalSystem from '../DesignSystem/ModalSystem';
import { ModificationRequestIcon } from '../../icons';
import './ModificationRequestsModal.scss';
import { useModificationRequestDates } from '../../hooks/useModificationRequestDates';
import ResponsibleList from '../ResponsibleList';

const ModificationRequestsModal = ({
  visible,
  taskId,
  modalModification,
  onCancel,
  onSend,
  onUpdateComment,
  t
}) => {
  const [buttonDisabled, setButtonDisabled] = useState(true);

  const {
    activityId,
    activityName,
    taskSubcontract,
    taskName,
    taskStartDate,
    taskEndDate,
    taskResponsibles,
    currStartDate,
    currEndDate,
    newStartDate,
    newEndDate,
    variance
  } = useModificationRequestDates({ taskId, modalModification });

  const handleComment = (e) => {
    setButtonDisabled(e.target.value === '');
    onUpdateComment(e.target.value);
  };

  useEffect(() => {
    if (modalModification.description === '') {
      setButtonDisabled(true);
    }
  }, [modalModification]);

  return (
    <ModalSystem
      title={t('modals.lookahead.activity_modification_request.title')}
      visible={visible}
      theme={'dark'}
      width={780}
      setVisible={onCancel}
      iconTitle={<ModificationRequestIcon color={'#7DFF8A'} />}
      showIconTitle={true}>
      <div className="modification-requests-modal content">
        <div className="activity-content">
          <div className="label-text">
            {t('modals.lookahead.activity_modification_request.activity_label')}
            :
          </div>
          <div className="content-text">
            {activityId} | {activityName}
          </div>
        </div>
        <div className="dates-content">
          <div className="dates-column">
            <div className="label-text">
              {t(
                'modals.lookahead.activity_modification_request.current_start_date_label'
              )}
              :
            </div>
            <div className="label-text">
              {t(
                'modals.lookahead.activity_modification_request.new_start_date_label'
              )}
              :
            </div>
          </div>
          <div className="dates-column">
            <div className="content-text px-10">{currStartDate}</div>
            <div className="content-text px-10 badge-dark-green">
              {newStartDate}
            </div>
          </div>
          <div className="dates-column">
            <div className="label-text">
              {t(
                'modals.lookahead.activity_modification_request.current_end_date_label'
              )}
              :
            </div>
            <div className="label-text">
              {t(
                'modals.lookahead.activity_modification_request.new_end_date_label'
              )}
              :
            </div>
          </div>
          <div className="dates-column">
            <div className="content-text px-10">{currEndDate}</div>
            <div className="content-text px-10 badge-dark-green">
              {newEndDate}
            </div>
          </div>
          <div className="dates-column-reverse">
            <div className="label-text">
              {t(
                'modals.lookahead.activity_modification_request.variance_label'
              )}
              :
            </div>
          </div>
          <div className="dates-column-reverse">
            <div className="content-text px-10 badge-dark-green text-yellow">
              {variance}
            </div>
          </div>
        </div>
        <div className="comment-content">
          <div className="label-text">
            {t('modals.lookahead.activity_modification_request.comment')}
          </div>
          <textarea
            className="textarea"
            value={modalModification.description}
            onChange={handleComment}
          />
        </div>
        <div className="responsible-task-content">
          <div className="label-text">
            {t(
              'modals.lookahead.activity_modification_request.task_responsible_title'
            )}
            :
          </div>
          <div className="content-text">{taskName}</div>
        </div>
        <div className="responsible-data-content">
          <div className="responsible-data-column-1">
            <div className="label-text">
              {t(
                'modals.lookahead.activity_modification_request.responsible_label'
              )}
              :
            </div>
            <div className="label-text">
              {t(
                'modals.lookahead.activity_modification_request.company_label'
              )}
              :
            </div>
          </div>
          <div className="responsible-data-column">
            <div className="responsible-data-row">
              <ResponsibleList responsibles={taskResponsibles} t={t} />
            </div>
            {taskSubcontract ? (
              <div className="responsible-data-row">
                <div
                  className="company-circle"
                  style={{ background: taskSubcontract.color }}
                />
                <div className="content-text">{taskSubcontract.name}</div>
              </div>
            ) : (
              <div className="content-text">
                {t('modals.lookahead.activity_modification_request.no_company')}
              </div>
            )}
          </div>
          <div className="responsible-data-column">
            <div className="label-text">
              {t(
                'modals.lookahead.activity_modification_request.start_date_label'
              )}
              :
            </div>
            <div className="label-text">
              {t(
                'modals.lookahead.activity_modification_request.end_date_label'
              )}
              :
            </div>
          </div>
          <div className="responsible-data-column">
            <div className="content-text">{taskStartDate}</div>
            <div className="content-text">{taskEndDate}</div>
          </div>
        </div>
        <div className="buttons">
          <button className="cancel" onClick={onCancel}>
            {t('modals.lookahead.activity_modification_request.cancel_option')}
          </button>
          <button
            className={`accept ${buttonDisabled && 'disabled'}`}
            disabled={buttonDisabled}
            onClick={onSend}>
            {t('modals.lookahead.activity_modification_request.send_request')}
          </button>
        </div>
      </div>
    </ModalSystem>
  );
};

export default ModificationRequestsModal;
