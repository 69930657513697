import * as icons from '../../icons';
import LookaheadVisualizationOptions from '../LookaheadVisualizationOptions';
import LookAheadPlanExport from '../exportable/LookAheadPlan';
import { Colors } from '../../constants/colors.constants.js';
import { isFeatureOn } from '../../utils/featureUtils';

export const ZOOM_LEVELS = ['DAYS', 'WEEKS', 'MONTH', 'QUARTERS', 'YEARS'];

export const TOOLBAR_LEFT_GROUP_ITEMS = [
  {
    props: {},
    comp: LookaheadVisualizationOptions
  },
  [
    {
      icon: icons.ScheduleModuleIcon,
      command: 'LOOKAHEAD',
      tooltipI18nKey: 'filters_label.lookahead_label'
    },
    {
      icon: icons.FilterToolbarProjectsIcon,
      command: 'FILTER',
      tooltipI18nKey: 'filters_label.filters_label'
    },
    {
      icon: icons.ArrowsToolbarProjectsIcon,
      command: 'ORDER',
      tooltipI18nKey: 'filters_label.order_label'
    },
    // {
    //   icon: icons.GroupIcon,
    //   command: 'GROUP',
    //   tooltipI18nKey: 'filters_label.group_label'
    // },
    {
      icon: icons.LayoutToolbarProjectsIcon,
      command: 'COLUMNS',
      tooltipI18nKey: 'filters_label.columns_label'
    }
  ]
];

export const COLOR_SCHEME_TYPE_SUBMENU_ITEMS = [
  {
    icon: icons.StatusCircleIcon,
    iconColor: Colors.DARK_MINT,
    i18nKey: 'status_only_label_constraint',
    command: 'CHANGE_COLOR_FOR_STATUS',
    isCheckable: true
  },
  {
    icon: icons.StatusSquareIcon,
    iconColor: Colors.DARK_MINT,
    i18nKey: 'lean_status_only',
    command: 'CHANGE_COLOR_FOR_LEANSTATUS',
    isCheckable: true
  },
  {
    icon: icons.WorkerIcon,
    iconColor: Colors.DARK_MINT,
    i18nKey: 'settings.subcontracts',
    command: 'CHANGE_COLOR_FOR_SUBCONTRACT',
    isCheckable: true
  },
  {
    icon: icons.TagIcon,
    iconColor: Colors.DARK_MINT,
    i18nKey: 'config_constraints.tags',
    command: 'CHANGE_COLOR_FOR_TAGS',
    isCheckable: true
  }
];

export const TOOLBAR_TIMELINE_OPTIONS = [
  {
    icon: icons.PinToolbarProjectsIcon,
    command: 'SCROLL_TO_TODAY',
    tooltipI18nKey: 'scroll_to_today',
    ignoreColumn: true
  },
  {
    command: 'SET_ZOOM_LEVEL',
    defaultValue: 'DAYS',
    tooltipI18nKey: 'change_zoom_level',
    ignoreColumn: true,
    options: ZOOM_LEVELS.map((level) => ({
      i18nKey: `zoom_levels.${level.toLowerCase()}`,
      value: level
    }))
  },
  {
    icon: icons.ColorsIcon,
    tooltipI18nKey: 'bar_colors',
    subitems: COLOR_SCHEME_TYPE_SUBMENU_ITEMS,
    ignoreColumn: true,
    visible: isFeatureOn('enable_color-schema')
  },
  {
    id: 'weekly-lookahead-preview',
    command: 'SHOW_PREVIEW',
    icon: icons.SeeIcon,
    invertTextColor: true,
    activeBtn: false,
    i18nKey: 'new_weekly_lookahead.preview'
  },
  {
    id: 'weekly-lookahead-integration-btn',
    icon: icons.HandshakeIcon,
    i18nKey: 'new_weekly_lookahead.commit_btn',
    command: 'COMMIT_WEEKLY_PLAN',
    iconColor: Colors.BLACK,
    mainColor: true,
    invertTextColor: true
  }
];

export const TOOLBAR_RIGTH_GROUP_ITEMS = [
  {
    id: 'download-options',
    icon: icons.BlankDocumentIcon,
    direction: 'RIGHT',
    tooltipI18nKey: 'filters_label.download_label',
    command: 'DOWNLOAD_OPTIONS',
    subitems: [
      {
        props: {},
        Comp: LookAheadPlanExport,
        icon: icons.ExcelIcon,
        iconColor: Colors.BLACK
      },
      {
        icon: icons.PdfIcon,
        iconColor: Colors.BLACK,
        i18nKey: 'week_interval_modal_view',
        command: 'DOWNLOAD_SCHEDULE'
      },
      {
        icon: icons.PdfIcon,
        iconColor: Colors.BLACK,
        i18nKey: 'export_lookahead_pdf_gantt_button',
        command: 'DOWNLOAD_PDF_GANTT'
      }
    ]
  }
];

export const TOOLBAR_ITEMS_TRACK = {
  CHANGE_COLOR_FOR: {
    eventName: 'lookahed_bar_color_selection',
    getParams: (value) => ({
      option_selected: value.replace('CHANGE_COLOR_FOR_', '')
    })
  },
  SHOW_PREVIEW: {
    eventName: 'lookahead_preview_filter'
  },
  SCROLL_TO_TODAY: {
    eventName: 'lookhead_scroll_today'
  },
  SET_ZOOM_LEVEL: {
    eventName: 'change_lookahead_zoom_level',
    getParams: (value) => ({
      zoom_level_selected: value
    })
  },
  COLUMNS: {
    eventName: 'lookahead_column_button_selection'
  },
  DOWNLOAD_OPTIONS: {
    eventName: 'lookahead_download_button_selection'
  }
};
