export const MAX_ITEMS_SHOW = 5;
export const COUNT_ITEMS_SHOW_DISPLAY = 5;
export const REGEX_EMAIL = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
export const TYPE_USER_ALREADY_IN_COMPANY = 'USER_ALREADY_IN_COMPANY';
export const TYPE_USER_ALREADY_IN_PROJECT = 'USER_ALREADY_IN_PROJECT';
export const SUPERADMIN_ROLE = 'superadmin';
export const EVENT_SOURCE = {
  projectSettings: 'Project Settings',
  companySettings: 'Company Settings',
  schedule: 'Schedule',
  lookahead: 'Lookahead',
  weeklyPlan: 'WeeklyPlan'
};

export const EVENT_SOURCE_URL_MAP = {
  [EVENT_SOURCE.projectSettings]: '/settings/project',
  [EVENT_SOURCE.companySettings]: '/settings/company',
  [EVENT_SOURCE.schedule]: '/gantt',
  [EVENT_SOURCE.lookahead]: '/lookahead',
  [EVENT_SOURCE.weeklyPlan]: '/weeklyplan'
};

export const TEMPLATE_ITEMS = {
  email: '',
  role: '',
  company: null,
  projectsAssigned: []
};

export const USER_ROLES = (t) => [
  { value: 'superadmin', label: t('positions.superadmin') },
  { value: 'admin', label: t('positions.admin') },
  { value: 'projectleader', label: t('positions.projectleader') },
  { value: 'planner', label: t('positions.planner') },
  { value: 'superintendent', label: t('positions.fieldprofessional') },
  { value: 'subtrade', label: t('positions.subtrade') },
  { value: 'lastplanner', label: t('positions.lastplanner') },
  { value: 'client', label: t('positions.client') },
  { value: 'manager', label: t('positions.manager') }
];
